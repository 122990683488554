export default class CategoryUpdateForm {
  constructor(data) {
    this.name = data?.name
    this.image = data?.image
    this.isActive = data?.isActive
    this.itemTypePrefix = data?.itemTypePrefix
    this.sortWeight = data?.sortWeight
    this.useForNewOrderItem = data?.useForNewOrderItem
  }
  static create(data) {
    return new CategoryUpdateForm(data)
  }
}
