export default class DiscountUpdateForm {
  constructor(data) {
    this.promotion = data?.promotion
    this.discountTarget = data?.discountTarget
    this.dateStart = data?.dateStart
    this.dateEnd = data?.dateEnd
    this.discountValueType = data?.discountValueType
    this.discountValue = data?.discountValue
  }
  static create(data) {
    return new DiscountUpdateForm(data)
  }
}
