export default class DiscountCodeUpdateForm {
  constructor(data) {
    this.code = data?.code
    this.issued = data?.issued
  }

  static create(data) {
    return new DiscountCodeUpdateForm(data)
  }
}
