<template>
  <div>
    <h1 class="text-h4">Управление скидками</h1>
    <v-divider class="my-2"></v-divider>
    <v-form ref="form" v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Редактирование промокода</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="form.data.code"
            label="Промокод"
            counter="36"
            hint="Значение промокода"
            :rules="form.rule.code"
          />
          <v-text-field
            v-model="form.data.issued"
            label="Тираж"
            counter="12"
            :rules="form.rule.issued"
            hint="Количество кодов, которые могут быть применены"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :to="{name: 'discount-code.list'}">Отмена</v-btn>
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { ruleNumeric, ruleRequired } from '@/utils/FormRules'
import { mapActions, mapState } from 'vuex'
import CategoryUpdateForm from '@/model/category/CategoryUpdateForm'
import MetaView from '@/components/ui/MetaView.vue'
import DropDownDatePicker from '@/components/ui/DropDownDatePicker.vue'
import DiscountUpdateForm from '@/model/discount/DiscountUpdateForm'
import DiscountCodeUpdateForm from '@/model/discount/DiscountCodeUpdateForm'
import ToastService from '@/service/ToastService'

export default {
  ...dashboardPageCommonAttributes,
  name: 'DiscountEditPage',
  props: {
    id: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      form: {
        valid: true,
        data: {
          code: '',
          issued: null
        },
        rule: {
          code: [ruleRequired()],
          issued: [ruleRequired(), ruleNumeric(), (v) => v > 0  || 'Значение должно быть больше нуля']
        }
      }
    }
  },
  computed: {
    ...mapState('discount_code_edit', ['item', 'busy'])
  },
  created() {
    this.fetchData({ id: this.id })
      .then(() => {
        this.form.data.code = this.item.code
        this.form.data.issued = this.item.issued
      })
  },
  methods: {
    ...mapActions('discount_code_edit', ['fetchData', 'updateDiscountCode']),
    submitForm() {
      const form = {
        ...DiscountCodeUpdateForm.create(this.form.data),
        issued: parseInt(this.form.data.issued)
      }

      this.updateDiscountCode({ form })
        .then(() => this.$router.push({ name: 'discount-code.list' }))
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    }
  }
}
</script>
