<template>
  <v-expansion-panels v-show="!loading" v-model="panelMetadata" class="mt-2" multiple>
    <v-expansion-panel>
      <v-expansion-panel-header class="title">Метадата</v-expansion-panel-header>
      <v-expansion-panel-content class="text-body-2">
        <div class="mb-2">
          <div class="text-caption grey--text text--darken-2">ID</div>
          <div>{{ id }}</div>
        </div>
        <div class="mb-2">
          <div class="text-caption grey--text text--darken-2">Создан</div>
          <div>{{ createdAt | formatDateTime }}</div>
        </div>
        <div v-if="createdBy" class="mb-2">
          <div class="text-caption grey--text text--darken-2">Автор</div>
          <div>{{ createdBy }}</div>
        </div>
        <div class="mb-2">
          <div class="text-caption grey--text text--darken-2">Изменен</div>
          <div>{{ updatedAt | formatDateTime }}</div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'MetaView',
  props: {
    id: {
      type: String,
      default: ''
    },
    createdAt: {
      type: String,
      default: ''
    },
    updatedAt: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    createdBy: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      panelMetadata: []
    }
  }
}
</script>

<style scoped>

</style>
